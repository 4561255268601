import React from "react";
import { Grid, Typography, Button, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../.././client-config";
import serializers from "../serializers";
// @ts-ignore
import LocationIcon from "../icon/LocationIcon.svg";
// @ts-ignore
import LocationIconCourse from "../icon/VisibleLocation.svg";
// @ts-ignore
import HeartIcon from "../icon/HeartIcon.svg";
import ContainerForSVG from "../containerForSVG";
import Dialog from "../popups/contactPopup";
import Rating from "@material-ui/lab/Rating";
// @ts-ignore
import StarIcon from "../icon/Star.svg";
// @ts-ignore
import EmptyStarIcon from "../icon/empty-star.svg";
import AdviserLikes from "./adviserLikes";
import AdviserLikesMobile from "./adviserLikesMobile";
import CampusCTA from "./blocks/reuseableCampusComponents/campusCTA";

const useStyles = makeStyles((theme) => ({
  grey: {
    backgroundColor: "#F5F7FA",
    paddingTop: "15px",
    paddingBottom: "30px",
  },

  imageHolder: {
    maxHeight: "176px",
    maxWidth: "176px",
  },

  imageHolderCourse: {
    maxHeight: "176px",
    maxWidth: "176px",
    "& a":{
      "& figure":{
        "& img":{
          border: "0.5px solid #AAA9E4",
          borderRadius: "72px",
        },
        "& div":{
          borderRadius: "72px",
        },
      }
    }
  },

  jobTitle: {
    fontWeight: 400,
    lineHeight: "20px",
    color: "#8C8CA2",
    paddingBottom: "10px",
    fontSize: "14px",
  },
  jobTitleCourse: {
    paddingBottom: "10px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#6464FF",
  },
  likesButtonContainer: {
    width: "100%",
    marginTop: "46px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  likesButtonContainerNoPaddingTop: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  profileText: {
    justifyContent: "space-between",
    paddingLeft: "24px",
  },
  nameText: {
    color: "#3C465F",
    fontSize: "16px",
    lineHeight: "24px",
  },
  nameTextCourse: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#3C465F",
  },
  contactMeButton: {
    borderRadius: "8px",
    width: "100%",
    maxWidth: "180px",
    height: "56px",
    backgroundColor: "#DBE3EF",
    [theme.breakpoints.down("sm")]: {
      marginTop: "28px",
      width: "50%",
      maxWidth: "100%",
    },
  },
  contactMeButtonText: {
    color: "#50586C",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    marginRight: 0,
  },
  contactMeButtonContainer: {
    alignItems: "center",
    paddingTop: "25px",
    paddingBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& div": {
      "& button": {
        marginTop: 0,
        "& span": {
          [theme.breakpoints.down("sm")]: {
            "& img": {
              display: "none",
            },
          },
          "& span": {
            [theme.breakpoints.down("sm")]: {
              marginRight: 0,
            },
          },
        },
      },
    },
  },
  location: {
    marginLeft: "8px",
    color: "#50586C",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "7px",
    },
  },
  locationCourse: {
    marginLeft: "8px",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "7px",
    },
    fontSize: "14px",
    lineHeight: "20px",
    color: "#3C465F",
  },
  viewAdviser: {
    textDecoration: "none",
    color: "#8C8CA2",
    width: "100%",
    textAlign: "center",

    borderRadius: "8px",
    marginTop: "16px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      textAlign: "center",
    },
  },
  viewAdviserCourse: {
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    color: "#3C465F",
    marginTop: "16px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      textAlign: "center",
    },
  },
  noTextDecoration: {
    textDecoration: "none",
  },
  viewAdviserSpan: {
    color: "#3C455C",
    textDecoration: "none",
    textTransform: "uppercase",
    display: "block",
    width: "100%",
    paddingTop: "16px",
    paddingBottom: "16px",
    background: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
    border: "0.5px solid #94A4D6",
    borderRadius: "8px",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    [theme.breakpoints.up("md")]:{
      paddingTop: "13px",
      paddingBottom: "13px",
    },
    [theme.breakpoints.down("md")]: {
      whiteSpace: "nowrap",
    },
  },

  viewAdviserSpanCourse: {
    textDecoration: "none",
    textTransform: "uppercase",
    display: "block",
    width: "100%",
    paddingTop: "16px",
    paddingBottom: "16px",
    background: "#FFFFFF",

    border: "1px solid #3C465F",
    textTransform: "uppercase",
    color: "#3C465F",
    paddingTop: "15px",
    paddingBottom: "15px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    [theme.breakpoints.down("md")]: {
      whiteSpace: "nowrap",
    },
  },
  buttonHolder: {
    paddingTop: "0.75rem",
    gap: "12px",
    flexWrap: "nowrap",
  },
  holderForCard: {
    height: "100%",
  },
}));

type AdviserProps = {
  amountOfLikes: number;
  averageRating: number;
  dashboardId: string;
  image: {
    _type: string;
    alt: string;
    asset: object;
    crop: object;
    hotspot: object;
    isAdviserFilteredImage: boolean;
    isPeopleBioImage: boolean;
  };
  jobTitle: string;
  location: {
    location: string;
  };
  name: string;
  profileSender: string;
  showAdviser?: boolean;
  isMapView?: boolean;
  isCoursePage?: boolean;
};

const AdviserCard = (props: AdviserProps): JSX.Element => {
  const {
    name,
    jobTitle,
    image,
    location,
    amountOfLikes,
    profileSender,
    showAdviser,
    dashboardId,
    averageRating,
    isMapView,
    isCoursePage,
  } = props;

  let mainImageWithType = image;

  if (image != null) {
    mainImageWithType._type = "mainImage";
    mainImageWithType.isPeopleBioImage = true;
    mainImageWithType.isAdviserFilteredImage = true;
  }

  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.holderForCard}>
        <Grid container  xs={12}>
          <Grid xs={4} item className={isCoursePage ? classes.imageHolderCourse : classes.imageHolder}>
            <Link to={profileSender}>
              <BasePortableText
                blocks={mainImageWithType}
                serializers={serializers}
                {...clientConfig.sanity}
              />
            </Link>
          </Grid>
          <Grid xs={8} container item className={classes.profileText}>
            <Grid>
              <Link to={profileSender} className={classes.noTextDecoration}>
                <Typography variant="h4" className={isCoursePage ? classes.nameTextCourse : classes.nameText}>
                  {name}
                </Typography>
              </Link>

              <Typography variant="body2" className={isCoursePage ? classes.jobTitleCourse : classes.jobTitle}>
                {jobTitle}
              </Typography>
              <ContainerForSVG>
                <img src={isCoursePage ? LocationIconCourse : LocationIcon} alt="LocationIcon" />
                <Typography variant="body2" className={isCoursePage ? classes.locationCourse : classes.location}>
                  {location && location.location}
                </Typography>
              </ContainerForSVG>
            </Grid>

            <Hidden mdUp>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                className={
                  averageRating != undefined &&
                  averageRating != null &&
                  averageRating > 0
                    ? classes.likesButtonContainerNoPaddingTop
                    : classes.likesButtonContainer
                }
              >
                <Hidden smDown implementation="js">
                  <AdviserLikes
                    amountOfLikes={amountOfLikes}
                    averageRating={averageRating}
                  />
                </Hidden>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12}>
            <AdviserLikesMobile
              isCoursePage={isCoursePage}
              amountOfLikes={amountOfLikes}
              averageRating={averageRating}
              isDirectoryCard={true}
            />
          </Grid>
        </Hidden>
        <Grid
          container
          xs={12}
          direction="row"
          alignItems="center"
          justify="center"
          className={classes.contactMeButtonContainer}
        >
          <Hidden mdUp implementation="js">
            <AdviserLikesMobile
              amountOfLikes={amountOfLikes}
              averageRating={averageRating}
              isDirectoryCard={true}
              isCoursePage={isCoursePage}
            />
          </Hidden>
          <Grid container className={classes.buttonHolder}>
            <Grid item xs={6}>
              <Typography variant="button" className={isCoursePage ? classes.viewAdviserCourse : classes.viewAdviser}>
                <Link to={profileSender} className={isCoursePage ? classes.viewAdviserSpanCourse : classes.viewAdviserSpan}>
                  View Profile
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Dialog
                nameOfAdviser={name}
                buttonText={
                  <Typography
                    variant="button"
                    className={classes.contactMeButtonText}
                  >
                    CONTACT
                  </Typography>
                }
                fullWidthButtonContainer={true}
                isCoursePage={isCoursePage}
                dashboardId={dashboardId}
                pinkCta={undefined}
                halfButtonPaddingTop={undefined}
                dispatch={undefined}
                isRecommendedAdviserButton={undefined}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default AdviserCard;
