import React, { useEffect, useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "gatsby";
import * as styles from "./adviserList.module.css";
import { Grid } from "@material-ui/core";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

function LocationDropdown(props) {
  const { locationChange, isCoursePage } = props;

  const [address, setAddress] = useState(null);

  const handleChange = function (address) {
    setAddress(address);
  }

  const searchOptions = {
    componentRestrictions: { country: ['uk'] }
  }

  const handleSelect = async function (address) {

    geocodeByAddress(address)
      .then(results => { setAddress(results[0].formatted_address); return getLatLng(results[0]); })
      .then(latLng => { props.locationChange(latLng, address); })
      .catch(error => console.error('Error', error));
  }


  return (
    <Grid item xs={12} md={7} className={isCoursePage ? styles.headerBlockInputCampus : styles.headerBlockInput}>
     <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={isCoursePage ? styles.searchDropdownContainerCampus : styles.searchDropdownContainer}>
            <input
              {...getInputProps({
                placeholder: 'Enter location',
                className: 'location-search-input',
              })}
              className={isCoursePage ? styles.searchDropdownCampus : styles.searchDropdown}
            />
            <div className={`${'autocomplete-dropdown-container'} ${suggestions.length >= 1 ? styles.autocompleteDropdownContainer : styles.autocompleteDropdownContainerInactive}`}>
              {suggestions.slice(0, 5).map((suggestion, index) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer', fontSize: '14px', color: '#2F2F4E', padding: '.5em' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer', fontSize: '14px', color: '#2F2F4E', padding: '.5em' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    className={index === 0 ? styles.locationOptionTop : (index === (suggestions.length - 1) ? styles.locationOptionBottom : styles.locationOption)}
                  >
                    <span
                      className={index === 0 ? styles.locationOptionTop : (index === (suggestions.length - 1) ? styles.locationOptionBottom : styles.locationOption)}
                    >{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Grid>
  )


}


export default LocationDropdown
