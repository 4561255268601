import React, { useEffect, useState } from "react";
import { buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
// @ts-ignore
import * as styles from "./adviserList.module.css";
import { getNumberOfLikes } from "../../lib/helpers";

import { makeStyles } from "@material-ui/core/styles";
import Container from "../../components/container";
import { Grid, Typography, Button } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// @ts-ignore
import HeartIcon from "../icon/HeartIcon.svg";
import ContainerForSVG from "../containerForSVG";
// @ts-ignore
import ButtonIcon from "../icon/ArrowRightSquare.svg";
import AdviserCard from "./adviserCard";

const useStyles = makeStyles((theme) => ({
  adviserCardHolder: {
    [theme.breakpoints.down("md")]: {
      padding: "8px !important",
    },
    [theme.breakpoints.up("md")]: {
      padding: "12px !important",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "15px !important",
    },
  },
  adviserCardHolderMap: {
    [theme.breakpoints.down("md")]: {
      // padding: "8px !important",
      scrollMarginTop: "400px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "4px 0px 4px 0px",
      marginRight: "8px",
    },

  },
  likes: {
    color: "#8C8CA1",
    marginRight: "17px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  amountOfLikes: {
    marginLeft: "10px",
    fontSize: "16px",
    lineHeight: "24px",
  },
  likesContainer: {
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    width: "130px",
    height: "48px",
    justifyContent: "center",
    alignItems: "center",
  },
  likesSmallContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  likesSmallContainerText: {
    justifyContent: "space-between",
  },
  contactMeButton: {
    borderRadius: "8px",
    width: "100%",
    height: "56px",
    backgroundColor: "#DBE3EF",
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
      marginTop: "28px",
    },
  },
  contactMeButtonText: {
    marginRight: "11px",
    color: "#3C455C",
  },
}));

function AdviserListCard(props) {
  const [likes, setLikes] = useState(0);

  const { adviser, isMapView, onRefChange, selectedAdviserHandler, isCoursePage } = props;

  const [selectedAdviser, setSelectedAdviser] = useState(null);

  const handleSelectedAdviser = () => {
    setSelectedAdviser(true);
  };

  const handleSelectedAdviserClickAway = () => {
    setSelectedAdviser(false);
  };

  var showCard = true;
  if (adviser.showAdviser == null) {
    showCard = false;
  } else {
    showCard = adviser.showAdviser;
  }

  var image = adviser.image;
  const classesMUI = useStyles();

  useEffect(() => {
    (async () => {
      var numberOfLikes = adviser.numberOfLikes; //await getNumberOfLikes(adviser.dashboardId);

      if (typeof numberOfLikes !== "undefined") {
        setLikes(numberOfLikes);
      }
     // console.log('pprops.selected: ', props.selectedAdviserId, ', dashboardId: ', adviser.dashboardId);

      if (props.selectedAdviserId && (props.selectedAdviserId === adviser.dashboardId)) {
        //console.log('setting selected adviser');

        setSelectedAdviser(true);
      } else {
      //  console.log('!setting selected adviser');
        setSelectedAdviser(false);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (props.selectedAdviserId && (props.selectedAdviserId === adviser.dashboardId)) {
        setSelectedAdviser(true);
      } else {
     //   console.log('!setting selected adviser');
        setSelectedAdviser(false);
      }
    })();
  }, [props.selectedAdviserId]);

  useEffect(() => {
    props.selectedAdviserHandler(selectedAdviser, adviser);

  }, [selectedAdviser]);

  var profile = isCoursePage ? "/visible/experts/profile/" : "/advisers/";

  if (adviser.slug != null) {
    profile += (adviser.slug.current + "/");
  }

  var email = "mailto:";

  if (adviser.email != null) {
    email += adviser.email;
  }

  var fa = adviser.femaleAdviser;

  if (!fa) {
    fa = "Male";
  } else {
    fa = "Female";
  }

  const classes =
    styles.filtersContainer +
    ` adviserListCard ` +
    `${(selectedAdviser && isMapView && (isCoursePage ? styles.adviserProfileBoxActiveCourse : styles.adviserProfileBoxActive)) ||
    (isCoursePage ? styles.adviserProfileBoxCampus : styles.adviserProfileBox)
    }`;

  if (showCard) {
    return (
      <>
        <Grid
          id={`adviser-${adviser.dashboardId}`}
          item
          xs={12}
          md={isMapView ? 12 : 4}
          className={isMapView ? classesMUI.adviserCardHolderMap : classesMUI.adviserCardHolder}
          ref={onRefChange}
        >
          <ClickAwayListener onClickAway={handleSelectedAdviserClickAway}>
            <div
              className={classes}
              data-likes={adviser.numberOfLikes}
              data-rating={adviser.numberOfLikes}
              data-female={fa}
              data-feetype={adviser.feeStructure}
              onClick={handleSelectedAdviser}
            >
              <AdviserCard
                name={adviser.name}
                image={image}
                location={adviser.location}
                amountOfLikes={adviser.numberOfLikes}
                averageRating={adviser.averageRating}
                jobTitle={adviser.jobTitle}
                profileSender={profile}
                dashboardId={adviser.dashboardId}
                isMapView={isMapView}
                isCoursePage={isCoursePage}
              />
            </div>
          </ClickAwayListener>
        </Grid>
      </>
    );
  } else {
    return null;
  }
}

export default AdviserListCard;
