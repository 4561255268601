import React, { useEffect, useState, useCallback } from "react";
import loadable from "@loadable/component"
import AdviserListCard from "./adviserListCard";
import LocationDropdown from "./locationDropdown";
import QualificationDropdown from "./qualificationDropdown";
import SpecialisationDropdown from "./specialisationDropdown";
import GeneralDropdown from "./generalDropdown";
import Container from "../../components/container";
// @ts-ignore
import * as styles from "./adviserList.module.css";
import AdviserListMap from "./adviserListMap";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import queryString from "query-string";
import { useLocation } from "@reach/router";

import { GetDistanceFromLatLonInMiles } from "../../lib/helpers";
import { Grid, Hidden, } from "@material-ui/core";
// @ts-ignore
import GridViewMobile from "./img/GridView.svg";
// @ts-ignore
import GridViewMobileClicked from "./img/GridViewClicked.svg";
// @ts-ignore
import GridViewDesktop from "./img/GridViewDesktop.svg";
// @ts-ignore
import GridViewDesktopClicked from "./img/GridViewDesktopClicked.svg";
// @ts-ignore
import MapViewMobile from "./img/MapView.svg";
// @ts-ignore
import MapViewMobileClicked from "./img/MapViewClicked.svg";
// @ts-ignore
import MapViewDesktop from "./img/MapViewDesktop.svg";
// @ts-ignore
import MapViewDesktopClicked from "./img/MapViewDesktopClicked.svg";
import AdvancedFilterButton from "./adviserAdvPopup";

const AdviserFilters = loadable(() => import("./adviserFilters"))

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      background:
        "#3C465F;",
      color: "white",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      height: "3.5em",
      width: "3.5em",
      borderRadius: "1.75em",
      [theme.breakpoints.down("sm")]: {
        height: "3em",
        width: "3em",
        borderRadius: "1.5em",
        fontSize: "9px",
      },
    },
    "& .MuiPaginationItem-ellipsis": {
      paddingTop: ".8em",
    },
    "& .MuiPaginationItem-icon": {
      fontSize: "1.5rem",
    },
    "& .MuiPaginationItem-root": {
      [theme.breakpoints.down("sm")]: {
        minWidth: "initial !important",
      },
    },
    "& ul > li > button:not(.Mui-selected),.MuiPaginationItem-ellipsis": {
      backgroundColor: "white",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#2F2F4E",
      height: "3.5em",
      width: "3.5em",
      borderRadius: "1.75em",
      [theme.breakpoints.down("sm")]: {
        height: "3em",
        width: "3em",
        borderRadius: "1.5em",
        fontSize: "9px",
      },
    },
  },
  mapContainer: {
    width: '65%',
    paddingLeft: "10px",
    height: "904px",
    "& > div": {
      height: "100% !important",
    },
  },
  mapContainerCourse: {
    width: '65%',
    paddingLeft: "10px",
    height: "904px",
    "& > div": {
      height: "100% !important",
      "& > div":{
        borderRadius: "16px",
      }
    },
  },
  mapContainerMobile: {
    width: "100%",
    position: "sticky",
    paddingBottom: "1rem",
    left: 0,
    top: 0,
    zIndex: 99,
  },
  listContainerVisible: {
    overflowX: "hidden",
    height: "100%",
    overflow: "scroll",
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    userSelect: "none",
    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '30px'
    },
    '&::-webkit-scrollbar-track': {
      // backgroundColor: 'rgba(148, 164, 214, .4)',
      backgroundColor: '#D4D4D4',
      opacity: '0.4',
      width: '4px',
      borderRadius: '30px'
    },
    '&::-webkit-scrollbar-thumb': {
      // backgroundColor: 'rgba(148, 164, 214, 1)',
      backgroundColor: '#6464FF',
      boxShadow: '0px 3px 4px rgba(111, 116, 126, 0.16)',
      borderRadius: '30px',
      width: '6px'
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '#6464FF #D4D4D4',
    // scrollbarColor: "#D4D4D4",
  },
  listContainer: {
    overflowX: "hidden",
    height: "100%",
    overflow: "scroll",
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    userSelect: "none",
    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '30px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(148, 164, 214, .4)',
      opacity: '0.4',
      width: '4px',
      borderRadius: '30px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(148, 164, 214, 1)',
      boxShadow: '0px 3px 4px rgba(111, 116, 126, 0.16)',
      borderRadius: '30px',
      width: '6px'
    },
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(148, 164, 214, 1) rgba(148, 164, 214, .4)'
  },
  holderForContainer: {

    padding: "8px 8px 8px 8px",
    width: '35%',
    background: "#FFFFFF",
    border: "0.5px solid #DBE3EF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    borderRadius: "8px",
    height: "904px",
  },
  holderForContainerMobile: {

    width: '100%',

  },
  listContainerMobile: {
    width: "100%",
  },
  containerForMapGridIcons: {
    "@media (min-width:1279px)": {
      flexWrap: "nowrap",
      marginLeft: "16px",
    },
  },
  containerForMapGridIconsCourse: {
    maxWidth: "fit-content",
    // marginLeft: "auto",
    marginLeft: "16px",
    "@media (max-width: 960px)":{
      marginLeft: "auto",
    },
    "@media (min-width:1279px)": {
      flexWrap: "nowrap",
      // marginLeft: "16px",
    },
  },
  loadingSpinner: {
    [theme.breakpoints.down("sm")]: {
      height: "16px !important",
      width: "16px !important"
    },
  }
}));

const useStylesCoursePagination = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "16px",
      color: "#ffffff",
      background: "#6464F6",
      borderRadius: "2px",
      '&:hover': {
        background: "#6464F6 !important",
      }
    },
    "& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)":
      {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "16px",
      color: "#3C465F",
        borderRadius: "2px",
      border: "0.5px solid #6464F6",
      },
    "& .MuiPaginationItem-icon": {
      backgroundColor: "yellow",
      "& > svg": {
        display: "none",
      },
    },
    "& ul > li:first-child > button:not(.Mui-selected)": {
      "&:after": {
        fontweight: 600,
        fontsize: "12px",
        lineheight: "16px",
        color: "#3C465F",
        content: "'Previous'",
      },
      "& > svg": {
        display: "none",
      },
    },
    "& ul > li:last-child > button:not(.Mui-selected)": {
      "&:after": {
        fontweight: 600,
        fontsize: "12px",
        lineheight: "16px",
        color: "#3C465F",
        content: "'Next'",
      },
      "& > svg": {
        display: "none",
      },
    },
  },
  paginationContainer: {
    paddingTop: "28px",
    display: "flex",
    justifyContent: "center",
  },
  placeholderContainer: {
    marginTop: "28px",
  },
}));
interface customWindow extends Window {
  dataLayer: any;
  scrollTop: any;
  trackAdviserListFilters: any;
}

declare const window: customWindow;

type DropdownTypes = {
  Value: string;
  Label: string | number;
}[];

function AdviserList(props) {
  const { title, listAdvisers, isCoursePage } = props;
  const [sortBy, setSortBy] = useState("rating");
  const [qualificationLabel, setQualificationLabel] =
    useState("All Qualification");
  const [specialisationLabel, setSpecialisationLabel] =
    useState("All Specialisms");
  const [feeStructureLabel, setFeeStructureLabel] = useState(
    "All Charging Models"
  );
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement>();
  const reachRouterLocation = useLocation();
  const [location, setLocation] = useState("all");
  const [qualification, setQualification] = useState('all');
  const [specialisation, setSpecialisation] = useState("all");
  const [feeStructure, setFeeStructure] = useState('all');
  const [gender, setGender] = useState("all");
  const [visibleAdviser, setVisibleAdviser] = useState(false);
  const [locationLabel, setLocationLabel] = useState("All Location");

  const [genderLabel, setGenderLabel] = useState("All Gender");

  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [advisers, setAdvisers] = useState([]);
  const [page, setPage] = useState(1);
  const [analyticsSelection, setAnalyticsSelection] = useState({});
  const [scrollTop, setScrollTop] = useState("");
  const [latLang, setLatLang] = useState(null);
  const [searchLatLang, setSearchLatLang] = useState(null);
  const [renderMap, setRenderMap] = useState(true);
  const [userCustomRadius, setUserRadius] = useState(40);

  const [searchAdvisers, setSearchAdvisers] = useState([]);

  //adviser selected from the map
  const [selectedAdviserId, setSelectedAdviserId] = useState('');

  //adviser selected from the list
  const [selectedAdviser, setSelectedAdviser] = useState(null);
  const [mapZoom, setMapZoom] = useState(null);

  const theme = useTheme();

  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const MapView = matchesDesktop ? MapViewDesktop : MapViewMobile;
  const MapViewClicked = matchesDesktop
    ? MapViewDesktopClicked
    : MapViewMobileClicked;
  const GridView = matchesDesktop ? GridViewDesktop : GridViewMobile;
  const GridViewClicked = matchesDesktop
    ? GridViewDesktopClicked
    : GridViewMobileClicked;
  const [mapViewActive, setMapViewActive] = useState(false);
  const [gridViewActive, setGridViewActive] = useState(true);

  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  useEffect(() => {

    const script = document.createElement("script");
    const head = document.querySelector("head");

    if (!document.getElementById("gmaps")){

      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBjgav1YJhz2rAx2CxiCEGzBVA_QswQDTw&v=3.exp&libraries=geometry,drawing,places`
      script.async = true;
      script.defer = true;
      script.id = "gmaps"

      head.appendChild(script)
      setIsGoogleMapsLoaded(true)
    }

    return () => {
      head.removeChild(script);
      setIsGoogleMapsLoaded(false)
    }
  },[])
  const handleMapClick = () => {
    setMapViewActive(true);
    setGridViewActive(false);
    var model = {
      Location: location,
      Specialisation: specialisation,
      Qualification: qualification,
      FeeStructure: feeStructure,
      Gender: gender,
      Offset: 0,
      ItemsPerPage: totalRows,
      SortBy: sortBy,
      VisibleAdviser: visibleAdviser,
    }

    setFilterAdvisors(model, false);
    // handleSearch();
  };

  const handleGridClick = () => {
    setGridViewActive(true);
    setMapViewActive(false);
    var model = {
      Location: location,
      Specialisation: specialisation,
      Qualification: qualification,
      FeeStructure: feeStructure,
      Gender: gender,
      Offset: 0,
      ItemsPerPage: itemsPerPage,
      SortBy: sortBy,
      VisibleAdviser: visibleAdviser,
    }
    setFilterAdvisors(model, true);
  };

  const handleSetUserRadius = (event) => {
    setUserRadius(parseInt(event.target.value))
  }
  useEffect(() => {
    (() => {
      searchAtPageLoad();
    })();
  }, []);

  useEffect(() => {
    (() => {
      if (scrollTop != "") {
        window.location.href = "#filters";
      }
    })();
  }, [scrollTop]);

  useEffect(() => {
    (() => {
      search(1);
    })();
  }, [sortBy]);

  useEffect(() => {
    if (gridViewActive) {
      var advancedFilterValues = getAdvancedFilterValues();

    var model = {
      Location: location,
      Specialisation: advancedFilterValues.Specialisation,
      Qualification: advancedFilterValues.Qualification,
      FeeStructure: advancedFilterValues.FeeStructure,
      Gender: gender,
      Offset: 0,
      ItemsPerPage: itemsPerPage,
      SortBy: sortBy,
      VisibleAdviser: visibleAdviser,
      }

    setFilterAdvisors(model, true);
    }
  }, [gridViewActive])

  useEffect(() => {
    (() => {
      if (JSON.stringify(analyticsSelection) === '{}') {
        return;
      }

      if (window.dataLayer) {
        window.dataLayer.push({ 'advisors_list_combined': JSON.stringify(analyticsSelection) });

        window.dataLayer.push({ event: 'advisors_list' });
      }

      //braze
      try {
        window.trackAdviserListFilters(analyticsSelection)
      } catch (e) {

      }
    })();
  }, [analyticsSelection]);

  const maleFemale = ["All advisers", "Male", "Female"];
  const likesRating = ["Order By", "Total Likes", "Rating"];
  const fees = ["All Fee Structures", "Percentage", "Fixed Fee"];
  const classes = useStyles();
  const coursePagination = useStylesCoursePagination();

  const genderDropdownOptions: DropdownTypes = [
    {
      Value: "all",
      Label: "All Genders",
    },
    {
      Value: "Female",
      Label: "Female",
    },
    {
      Value: "Male",
      Label: "Male",
    },
  ];

  const feeStructureDropdownOptions: DropdownTypes = [
    {
      Value: "all",
      Label: "All Charging Models",
    },
    {
      Value: "Ongoing % fee",
      Label: "Ongoing % fee",
    },
    {
      Value: "Fixed £ fee",
      Label: "Fixed " + String.fromCharCode(163) + " fee",
    },
  ];

  const handlePageChange = (event, value) => {
    setScrollTop(scrollTop + ".");
    const headerEl = document.getElementById("adviser-header")
    headerEl.scrollIntoView()
    search(value);
  };

  var qualificationsChange = function (event) {
    setQualificationLabel(event.target.selectedOptions[0].label);
    setQualification(event.target.value);
  };

  var specialisationChange = function (event) {
    setSpecialisationLabel(event.target.selectedOptions[0].label);
    setSpecialisation(event.target.value);
  };

  var locationChange = function (latLang, locationString) {

    setLatLang(latLang);
    setLocationLabel(locationString + '. Latitude: ' + latLang.lat + '. Longitude: ' + latLang.lng);
    //setLocation(event.target.value);
  }


  var sortByChange = function (event) {
    setSortBy(event.target.value);
  };

  var feeStructureChange = function (event) {
    setFeeStructureLabel(event.target.selectedOptions[0].label);
    setFeeStructure(event.target.value);
  }

  var genderChange = function (event) {
    setGenderLabel(event.target.selectedOptions[0].label);
    setGender(event.target.value);
  }

  var setFilterAdvisors = function (model, skipMapRefresh) {
    var filteredAdvisers = [];

    if (model.SortBy === 'likes') {
      filteredAdvisers = listAdvisers.sort(function (a, b) {
        return b.numberOfLikes - a.numberOfLikes;
      });
    }

    if (model.SortBy === 'mostActive') {
      filteredAdvisers = listAdvisers.sort(function (a, b) {
        return b.answeredQuestionsRank - a.answeredQuestionsRank || b.dateAnsweredRank - a.dateAnsweredRank;
      });
    }

    if (model.SortBy === 'rating') {
      filteredAdvisers = listAdvisers.sort(function (a, b) {
        return b.averageRating - a.averageRating || b.numberOfRatings - a.numberOfRatings || b.numberOfLikes - a.numberOfLikes;
      });
    }

    filteredAdvisers = filteredAdvisers.filter(function (item) {

      //console.log("filtered adv func", item)

      // var filteredVisibleAdviser = item.isVisibleAdviser.filter(function (visibleAdviserItem) {
      //   return visibleAdviserItem === true
      // })

      var visibleAdviserMatch = item.isVisibleAdviser === true;

      var filteredFeeStructures = item.feeStructure.filter(function (feeStructureItem) {
        return feeStructureItem === model.FeeStructure;
      });

      var feeStructureMatch = model.FeeStructure === 'all' || filteredFeeStructures.length > 0;

      var filteredQualifications = item.qualification.filter(function (qualificationItem) {
        return qualificationItem.qualification === model.Qualification;
      });

      var qualificationMatch = model.Qualification === 'all' || filteredQualifications.length > 0;

      var filteredSpecialisations = item.specialisations.filter(function (specialisationItem) {
        return specialisationItem.specialisation === model.Specialisation;
      });

      var specialisationMatch = model.Specialisation === 'all' || filteredSpecialisations.length > 0;

      var locationMatch = null;

      locationMatch = false;

      if (!latLang) {
        locationMatch = true;
      }

      if (item.latitude && item.longitude && item.radius && latLang) {
        var userRadius = userCustomRadius;

        var distance = GetDistanceFromLatLonInMiles(latLang.lat, latLang.lng, item.latitude, item.longitude);

        locationMatch = distance < (userRadius + parseInt(item.radius));
      }

      var genderMatch = model.Gender === 'all' || (item.femaleAdviser && model.Gender === 'Female') || (!item.femaleAdviser && model.Gender === 'Male');

      if (isCoursePage) {
        return locationMatch && qualificationMatch && specialisationMatch && feeStructureMatch && genderMatch && visibleAdviserMatch;
      } else {
        return locationMatch && qualificationMatch && specialisationMatch && feeStructureMatch && genderMatch;
      }
    });

    var numberOfResults = filteredAdvisers.length;
    var slice = filteredAdvisers.slice(model.Offset, model.Offset + model.ItemsPerPage);

    setTotalRows(numberOfResults)
    setTotalPages(Math.ceil(numberOfResults / itemsPerPage));

    if (mapViewActive) {
      setAdvisers(filteredAdvisers);

      if (!skipMapRefresh) {
        setSearchAdvisers(filteredAdvisers);
      }
    } else {
      setAdvisers(slice);
    }
  }

  var getAdvancedFilterValues = function () {
    var queryStringSpecialisation = queryString.parse(reachRouterLocation.search).specialism;
    var queryStringQualification = queryString.parse(reachRouterLocation.search).qualification;
    var queryStringFeeStructure = queryString.parse(reachRouterLocation.search).chargingModel;

    var searchSpecialisation = specialisation;

    if (queryStringSpecialisation) {
      searchSpecialisation = decodeURIComponent(queryStringSpecialisation);
      setSpecialisation(searchSpecialisation);

      setSpecialisationLabel(searchSpecialisation);
    }

    var searchQualification = qualification;

    if (queryStringQualification) {
      searchQualification = decodeURIComponent(queryStringQualification);

      setQualification(searchQualification);
      setQualificationLabel(searchQualification);
    }

    var searchFeeStructure = feeStructure;

    if (queryStringFeeStructure) {
      searchFeeStructure = decodeURIComponent(queryStringFeeStructure);

      if (searchFeeStructure === 'ongoing') {
        var ongoingFeeString = 'Ongoing % fee';

        setFeeStructureLabel(ongoingFeeString);
        setFeeStructure(ongoingFeeString);
        searchFeeStructure = ongoingFeeString;
      } else

        if (searchFeeStructure === 'fixed') {
          var fixedFeeString = 'Fixed £ fee';
          setFeeStructureLabel(fixedFeeString);
          setFeeStructure(fixedFeeString);
          searchFeeStructure = fixedFeeString;

        } else {
          setFeeStructure('all');
          setFeeStructureLabel('all');
        }
    }

    var advancedFilterValues = {
      FeeStructure: searchFeeStructure,
      Qualification: searchQualification,
      Specialisation: searchSpecialisation
    }

    return advancedFilterValues;
  }
  var searchAtPageLoad = function () {
    var advancedFilterValues = getAdvancedFilterValues();

    var model = {
      Location: location,
      Specialisation: advancedFilterValues.Specialisation,
      Qualification: advancedFilterValues.Qualification,
      FeeStructure: advancedFilterValues.FeeStructure,
      Gender: gender,
      Offset: 0,
      ItemsPerPage: itemsPerPage,
      SortBy: sortBy,
      VisibleAdviser: visibleAdviser,
    }

    setSearchLatLang({
      lat: 54.6549164,
      lng: -4.8254444
    });

    setFilterAdvisors(model, false);
  }

  var handleSearch = function () {
    setAnalyticsSelection({
      Qualification: qualificationLabel,
      Specialisation: specialisationLabel,
      Location: locationLabel,
      Radius: userCustomRadius,
      FeeStructure: feeStructureLabel,
      Gender: genderLabel,
      SortBy: sortBy
    });

    if (mapViewActive) {
      setSearchLatLang(latLang);
    }

    search(1);
  }

  var search = function (newPageNumber) {
    setPage(newPageNumber);
    var newPageOffset = 0;

    if (totalRows <= itemsPerPage || newPageNumber === 1) {
      newPageOffset = 0;
    }
    else {
      newPageOffset = (newPageNumber - 1) * itemsPerPage;
    }

    var model = {
      Location: location,
      Specialisation: specialisation,
      Qualification: qualification,
      FeeStructure: feeStructure,
      Gender: gender,
      Offset: newPageOffset,
      ItemsPerPage: itemsPerPage,
      SortBy: sortBy,
      VisibleAdviser: visibleAdviser,
    }

    setFilterAdvisors(model, false);
  }

  var markerClick = function (dashboardId) {
    if (matchesDesktop) {
      setSelectedAdviserId(dashboardId);

      var myElement = document.getElementById("adviser-" + dashboardId);

      var topPosition = myElement.offsetTop;

      document.getElementById("scroll-container").scrollTop = topPosition - 700;
    } else {
      setSelectedAdviserId(dashboardId);
      var myElement = document.getElementById("adviser-" + dashboardId);
      myElement.scrollIntoView();
    }
  };

  const selectedAdviserHandler = function (isSelected, adviser) {
    if (isSelected && adviser.dashboardId !== selectedAdviserId) {
      setMapZoom(15);
      setTimeout(() => {
        setSelectedAdviser(adviser);
      }
        , 0);

    }
  }
  const onListLoad = useCallback((node) => {
    if (node !== null) {
      setScrollContainer(node);
    } else {
      return null;
    }
  }, []);

  let pos = { top: 0, left: 0, x: 0, y: 0 };

    const mouseDownHandler = function (e) {
      scrollContainer.style.cursor = "grabbing";
      scrollContainer.style.userSelect = "none";

      pos = {
        left: scrollContainer.scrollLeft,
        top: scrollContainer.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };

      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {

      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      scrollContainer.scrollTop = pos.top - dy;
      scrollContainer.scrollLeft = pos.left - dx;
    };

    const mouseUpHandler = function () {
      scrollContainer.style.cursor = "grab";
      scrollContainer.style.removeProperty("user-select");

      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };

  return (
    <Grid>
      {isGoogleMapsLoaded &&
        <AdviserFilters
          styles={styles}
          LocationDropdown={LocationDropdown}
          locationChange={locationChange}
          QualificationDropdown={QualificationDropdown}
          qualificationsChange={qualificationsChange}
          SpecialisationDropdown={SpecialisationDropdown}
          handleSearch={handleSearch}
          feeStructureChange={feeStructureChange}
          specialisationChange={specialisationChange}
          feeStructureDropdownOptions={feeStructureDropdownOptions}
          genderChange={genderChange}
          genderDropdownOptions={genderDropdownOptions}
          sortByChange={sortByChange}
          qualificationLabel={qualificationLabel}
          specialisationLabel={specialisationLabel}
          feeStructureLabel={feeStructureLabel}
          genderLabel={genderLabel}
          handleSetUserRadius={handleSetUserRadius}
          isCoursePage={isCoursePage}
          isGoogleMapsLoaded={isGoogleMapsLoaded}
          setIsGoogleMapsLoaded={setIsGoogleMapsLoaded}
        />
      }
      <Container>
        <div className={styles.filteredQuestionsContainer}>
          <Hidden mdUp>
            <Grid className={styles.adviserAmountContainer}>
              <span className={isCoursePage ? styles.adviserAmountCampus : styles.adviserAmount}>
                  {totalRows}&nbsp;{isCoursePage ? "Experts" : "Advisers"}
              </span>
            </Grid>
          </Hidden>
          <Grid className={styles.basicSortContainer} container>
            <Hidden smDown>
              <Grid item md={2} className={styles.adviserAmountContainer} >
                <span className={isCoursePage ? styles.adviserAmountCampus : styles.adviserAmount}>
                    {totalRows}&nbsp;{isCoursePage ? "Experts" : "Advisers"}
                </span>
              </Grid>
            </Hidden>
            <Grid
              item
              xs={6}
              md={7}
              lg={isCoursePage ? "auto" : 8}
              className={isCoursePage ? styles.dropDownContainerCourse : styles.dropDownContainer}
            >
              <select
                className={isCoursePage ? styles.filterDropdownCampus : styles.filterDropdown}
                id="sort-by-dropdown"
                onChange={sortByChange}
              >
                <option value="rating">Sort by</option>
                <option value="likes">Likes</option>
                <option value="mostActive">Most active</option>
                <option value="rating">Rating</option>
              </select>
            </Grid>
            <Grid className={isCoursePage ? classes.containerForMapGridIconsCourse : classes.containerForMapGridIcons} item xs={6} md={3} lg={2} container justifyContent="flex-end">
              {isCoursePage ?
                <>
                  <button className={gridViewActive ? styles.gridIconCampusClicked : styles.gridIconCampusUnclicked} onClick={handleGridClick}>Grid</button>
                  <button className={mapViewActive ? styles.gridIconCampusClicked : styles.gridIconCampusUnclicked} onClick={handleMapClick} >Map</button>
                </> :
                <>
                <img
                  src={gridViewActive ? GridViewClicked : GridView}
                  className={styles.gridMapIcons}
                  onClick={handleGridClick}
                />
                <img
                src={mapViewActive ? MapViewClicked : MapView}
                onClick={handleMapClick}
                className={styles.gridMapIcons}
                /></>
              }
            </Grid>
          </Grid>
          {gridViewActive && <Grid container className={styles.adviserCardContainer} spacing={3}>
            {advisers &&
              Array.isArray(advisers) &&
              advisers.map((adv) => {
                return <AdviserListCard adviser={adv} selectedAdviserHandler={selectedAdviserHandler} isCoursePage={isCoursePage} />;
              })}
          </Grid>
          }
          {mapViewActive && <Grid container className={''}>
            <Grid container className={matchesDesktop ? classes.holderForContainer : classes.holderForContainerMobile}>
              <Hidden mdUp>
                <Grid item className={matchesDesktop ? (isCoursePage ? classes.mapContainerCourse : classes.mapContainer) : classes.mapContainerMobile}>
                  {renderMap && <AdviserListMap
                    // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBjgav1YJhz2rAx2CxiCEGzBVA_QswQDTw&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    markers={advisers}
                    latLang={searchLatLang}
                    markerClick={markerClick}
                    selectedAdviser={selectedAdviser}
                    requestedZoom={mapZoom}
                  />}
                </Grid>
              </Hidden>
              <Grid item id={'scroll-container'} className={matchesDesktop ? `${isCoursePage ? classes.listContainerVisible : classes.listContainer}` : classes.listContainerMobile} ref={onListLoad} onMouseDown={mouseDownHandler}>
                {advisers &&
                  Array.isArray(advisers) &&
                  advisers.map((adv) => {
                    return <AdviserListCard adviser={adv} isMapView={true} selectedAdviserId={selectedAdviserId} selectedAdviserHandler={selectedAdviserHandler} isCoursePage={isCoursePage} />;
                  })}
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item className={matchesDesktop ? (isCoursePage ? classes.mapContainerCourse : classes.mapContainer) : classes.mapContainerMobile}>
                {renderMap && <AdviserListMap
                  // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBjgav1YJhz2rAx2CxiCEGzBVA_QswQDTw&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  markers={advisers}
                  latLang={searchLatLang}
                  markerClick={markerClick}
                  selectedAdviser={selectedAdviser}
                  requestedZoom={mapZoom}
                />}
              </Grid>
            </Hidden>
          </Grid>
          }
        </div>
        {!mapViewActive &&
          <Grid
            container
            alignItems="center"
            justify="center"
            className={styles.paginationContainer}
          >
            <Grid item>
              {advisers.length > 0 && (
                <Pagination
                  count={totalPages}
                  page={page}
                  className={isCoursePage ? coursePagination.root : classes.root}
                  onChange={handlePageChange}
                />
              )}
            </Grid>
          </Grid>
        }
      </Container>
    </Grid>
  );
}

export default AdviserList;
