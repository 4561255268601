import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Link } from "gatsby";
import * as styles from "./adviserList.module.css";
import { Grid } from "@material-ui/core";

const QualificationDropdown = ({
  qualificationsChange,
  customClassName,
  qualificationLabel,
}) => (
  <StaticQuery
    query={graphql`
      {
        posts: allSanityQualificationCategory {
          edges {
            node {
              qualification
              id
            }
          }
        }
      }
    `}
    render={(data) => (
      <Grid item>
        <select
          id="ddQualifications"
          className={customClassName ? customClassName : styles.filterDropdown}
          onChange={qualificationsChange}
          value={qualificationLabel}
        >
          <option value="all">All Qualifications</option>
          {data.posts.edges &&
            data.posts.edges.map((node) => <LinkList items={node} />)}
        </select>
      </Grid>
    )}
  ></StaticQuery>
);
function LinkList({ items }) {
  const json = JSON.parse(JSON.stringify(items.node, null, 4));

  return <option value={json.qualification}>{json.qualification}</option>;
}

export default QualificationDropdown;
