import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Link } from "gatsby";
import * as styles from "./adviserList.module.css";
import { Grid } from "@material-ui/core";

const QualificationDropdown = ({ specialisationChange, customClassName, specialisationLabel, isCoursePage }) => (
  <StaticQuery
    query={graphql`
      {
        posts: allSanitySpecialisationCategory {
          edges {
            node {
              id
              specialisation
              displayVisibleSpecialisation
            }
          }
        }
      }
    `}
    render={(data) => (
      <Grid item>
        <select
          id="ddSpecialisation"
          className={customClassName ? customClassName : styles.filterDropdown}
          onChange={specialisationChange}
          value={specialisationLabel}
        >
          <option value="all">{isCoursePage ? 'All Topics' : 'All Specialisms'}</option>
          {data.posts.edges &&
            data.posts.edges
              .sort(function (a, b) {
                if (a.node.specialisation < b.node.specialisation) {
                  return -1;
                }
                if (a.node.specialisation > b.node.specialisation) {
                  return 1;
                }
                return 0;
              })
              .map((node) => <LinkList items={node} isCoursePage={isCoursePage}/>)}
        </select>
      </Grid>
    )}
  ></StaticQuery>
);
function LinkList({ items, isCoursePage }) {
  const json = JSON.parse(JSON.stringify(items.node, null, 4));

  if (isCoursePage && !json.displayVisibleSpecialisation){
    return null;
  }
  if (!isCoursePage && json.displayVisibleSpecialisation){
    return null;
  }
  return <option value={json.specialisation}>{json.specialisation}</option>;
}

export default QualificationDropdown;
