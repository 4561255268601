import React, { useState, useEffect, useRef } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { makeStyles } from "@material-ui/core/styles";
//import { MarkerClusterer } from '@react-google-maps/api';
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

const useStyles = makeStyles((theme) => ({
  grey: {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    border: '5px solid white'
  },
  greyActive: {
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    border: '5px solid #65689F'
  },
  fiveZoomHeightActive: {
    border: '2.5px solid #65689F !important',

  },
  sixZoomHeightActive: {
    border: '3px solid #65689F !important',

  },
  sevenZoomHeightActive: {
    border: '4px solid #65689F !important',

  },
  eightZoomHeightActive: {
    border: '5px solid #65689F !important',
  },
  fiveZoomHeight: {
    borderRadius: '12.5px',
    height: '25px',
    width: '25px',
    border: '2.5px solid white',
  },
  sixZoomHeight: {
    borderRadius: '15px',
    height: '30px',
    width: '30px',
    border: '3px solid white',
  },
  sevenZoomHeight: {
    borderRadius: '20px',
    height: '40px',
    width: '40px',
    border: '4px solid white',
  },
  eightZoomHeight: {
    borderRadius: '25px',
    height: '50px',
    width: '50px',
    border: '5px solid white',
  },
  overlay: {
    position: 'absolute',
    opacity: '0.35',
    background: 'linear-gradient(90deg, #FBC2EB 0%, #A6C1EE 100%)',
    width: '100%',
    height: '100%',
    top: 0,
    borderRadius: '25px'
  },
  clusterClass: {
    background: '#3C465F',
    color: '#505285',
    backgroundColor: '#505285'
  }
}));
const AdviserListMap = withGoogleMap((props) => {
  const mapRef = useRef(null);
  const [activeMarkerId, setActiveMarkerId] = useState('');
  const [zoom, setZoom] = useState(5);
  const [zoomClass, setZoomClass] = useState('');
  const [zoomClassActive, setZoomClassActive] = useState('');
  const [center, setCenter] = useState({
    lat: 51.5072178,
    lng: -0.1275862
  });
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (markers && markers.length > 0) {

      const bounds = new window.google.maps.LatLngBounds();

      markers.map(item => {
        if (item.latitude && item.longitude) {
          bounds.extend(new window.google.maps.LatLng(Number(item.latitude), Number(item.longitude)));
        }

        return item.id
      });

      mapRef.current.fitBounds(bounds);
    }

    setCenter({
      lat: (props.latLang && props.latLang.lat) ? Number(props.latLang.lat) : 51.5072178,
      lng: (props.latLang && props.latLang.lng) ? Number(props.latLang.lng) : -0.1275862
    }
    )

    setMarkers(props.markers);
  }, []);

  useEffect(() => {
    if (props.latLang) {
      if (markers && markers.length > 0) {

        const bounds = new window.google.maps.LatLngBounds();

        markers.map(item => {
          if (item.latitude && item.longitude) {
            bounds.extend(new window.google.maps.LatLng(Number(item.latitude), Number(item.longitude)));
          }

          return item.id
        });

        mapRef.current.fitBounds(bounds);
      }

      setCenter({
        lat: Number(props.latLang.lat),
        lng: Number(props.latLang.lng)
      })
    }

  }, [props.latLang]);

  useEffect(() => {
    setMarkers(props.markers);
  }, [props.markers]);

  useEffect(() => {
    if (props.requestedZoom) {
      setZoom(props.requestedZoom);
    }

  }, [props.requestedZoom]);

  useEffect(() => {
    if (props.selectedAdviser) {
      if (props.selectedAdviser === {}) {
        setActiveMarkerId('00000000-0000-0000-0000-000000000000');
      }
      else {
        setActiveMarkerId(props.selectedAdviser.dashboardId);

        setCenter({
          lat: Number(props.selectedAdviser.latitude),
          lng: Number(props.selectedAdviser.longitude)
        });

        setZoom(15);
      }
    }
  }, [props.selectedAdviser]);

  useEffect(() => {
    if (zoom <= 5) {
      setZoomClass(classes.sixZoomHeight);
      setZoomClassActive(classes.sixZoomHeightActive);
    }

    if (zoom === 6) {
      setZoomClass(classes.sixZoomHeight);
      setZoomClassActive(classes.sixZoomHeightActive);
    }

    if (zoom === 7) {
      setZoomClass(classes.sevenZoomHeight);
      setZoomClassActive(classes.sevenZoomHeightActive);
    }
    if (zoom >= 8) {
      setZoomClass(classes.eightZoomHeight);
      setZoomClassActive(classes.eightZoomHeightActive);
    }

  }, [zoom]);
  const classes = useStyles();


  const markerClick = function (marker) {
    setActiveMarkerId(marker.dashboardId);

    props.markerClick(marker.dashboardId);
  }

  const options = {
    minZoom: 6,
    maxZoom: 110,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: true
  }

  const onZoomChanged = function () {
    var zoom = mapRef.current.getZoom();

    if (zoom > 15) {
      setZoom(15);
    }
    else {
      setZoom(mapRef.current.getZoom());
    }
  }

  const onCenterChanged = function () {
    const center = mapRef.current.getCenter();
    setCenter({
      lat: center.lat(),
      lng: center.lng()
    });
  }

  const clusterOptions = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  }

  return (

    <GoogleMap
      defaultZoom={5}
      zoom={zoom}
      center={center}
      ref={mapRef}
      options={options}
      onZoomChanged={onZoomChanged}
      onCenterChanged={onCenterChanged}
      defaultOptions={{ mapTypeControl: false }}
    >
      <MarkerClusterer averageCenter
        enableRetinaIcons={true}
        gridSize={60}
        styles={[
          {
            url: "/icon/mapsCluster/icon1.png",
            height: 53,
            width: 52,
            fontFamily: "Lato",
            textColor: "#FFF",
          },
          {
            url: "/icon/mapsCluster/icon1.png",
            height: 53,
            width: 52,
            fontFamily: "Lato",
            textColor: "#FFF",
          },
          {
            url: "/icon/mapsCluster/icon1.png",
            height: 53,
            width: 52,
            fontFamily: "Lato",
            textColor: "#FFF",
          },
          {
            url: "/icon/mapsCluster/icon1.png",
            height: 53,
            width: 52,
            fontFamily: "Lato",
            textColor: "#FFF",
          },
          {
            url: "/icon/mapsCluster/icon1.png",
            height: 53,
            width: 52,
            fontFamily: "Lato",
            textColor: "#FFF",
          }
        ]}
      >
        {
          markers.map((marker) => {
            return (marker.latitude && marker.longitude) ? <MarkerWithLabel
              position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
              labelAnchor={{ x: 25, y: 25 }}
              labelStyle={{ backgroundColor: "rgba(0, 0, 0, 0)", fontSize: "8px" }}
              onClick={() => markerClick(marker)}
              icon={' '}
            >


              <div>
                <img className={`${marker.dashboardId === activeMarkerId ? zoomClassActive : ''} ${zoomClass}`} src={imageUrlFor(buildImageObj(marker.image))
                  .width(50)
                  .height(50)
                  .fit("crop")
                  .url()} />
                <div className={classes.overlay}></div>
              </div>
            </MarkerWithLabel> : <></>;
          })
        }
      </MarkerClusterer>
      {/*
        props.markers && props.markers.map((marker) => {
          return (marker.latitude && marker.longitude) ? <MarkerWithLabel
            position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
            labelAnchor={{ x: 25, y: 25 }}
            labelStyle={{ backgroundColor: "rgba(0, 0, 0, 0)", fontSize: "8px" }}
            onClick={() => markerClick(marker)}
            icon={' '}
          >


            <div>
              <img className={`${marker.dashboardId === activeMarkerId ? zoomClassActive : ''} ${zoomClass}`} src={imageUrlFor(buildImageObj(marker.image))
                .width(50)
                .height(50)
                .fit("crop")
                .url()} />
              <div className={classes.overlay}></div>
            </div>
          </MarkerWithLabel> : <></>;
        })*/
      }
    </GoogleMap>
  )
}
);

export default AdviserListMap;
